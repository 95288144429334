body,
div,
ol,
pre,
span,
ul,
aside,
.ant-layout-sider {
    scrollbar-width: 2px;
    scrollbar-color: #eff2f5 transparent;
}

div::-webkit-scrollbar,
ol::-webkit-scrollbar,
pre::-webkit-scrollbar,
span::-webkit-scrollbar,
ul::-webkit-scrollbar,
body::-webkit-scrollbar,
aside::-webkit-scrollbar,
.ant-layout-sider::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.4rem;
}

div::-webkit-scrollbar-thumb,
ol::-webkit-scrollbar-thumb,
pre::-webkit-scrollbar-thumb,
span::-webkit-scrollbar-thumb,
ul::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb,
aside::-webkit-scrollbar-thumb,
.ant-layout-sider::-webkit-scrollbar-thumb {
    background-color: #eff2f5;
    outline: 1px solid rgb(203, 206, 209);
    border-radius: 5px;
}

div:hover,
ol:hover,
pre:hover,
span:hover,
ul:hover,
aside:hover,
.ant-layout-sider:hover {
    scrollbar-color: #e9edf1 transparent;
}

div:hover::-webkit-scrollbar-thumb,
ol:hover::-webkit-scrollbar-thumb,
pre:hover::-webkit-scrollbar-thumb,
span:hover::-webkit-scrollbar-thumb,
ul:hover::-webkit-scrollbar-thumb,
body:hover::-webkit-scrollbar-thumb,
aside:hover::-webkit-scrollbar-thumb,
.ant-layout-sider:hover::-webkit-scrollbar-thumb {
    background-color: #e9edf1;
}

/* WebKit (Chrome, Safari) */
::-webkit-scrollbar {
    width: 4px;
}

::-webkit-scrollbar-thumb {
    background-color: #333333;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #555555;
}

// ::-webkit-scrollbar-track {
//     background-color: #181824;
// }

/* Firefox */
.ant-layout-sider {
    scrollbar-width: thin;
}

.ant-layout-sider::-webkit-scrollbar {
    width: 8px;
}

.ant-layout-sider::-webkit-scrollbar-thumb {
    background-color: #333333;
}

.ant-layout-sider::-webkit-scrollbar-thumb:hover {
    background-color: #555555;
}

.ant-layout-sider::-webkit-scrollbar-track {
    background-color: #181824;
}
.ant-layout-sider-collapsed {
    display: none;
}
