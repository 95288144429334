.jv-tags {
    &__tag {
        padding: 1px 8px;
        font-weight: 400;
        display: inline-flex;
        align-items: center;
        border-radius: 13px;
        margin-top: 0.5em;
        color: rgb(201 118 145);
        background: rgb(238 217 220 / 48%);
        border: 1px solid rgb(201 118 145);
    }
    &__capital {
        text-transform: capitalize;
    }
}

.jv-filters {
    margin: 0 15px 20px 15px;
    padding: 15px 16px;
    color: #3f4254;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: -1px -1px 8px 2px rgba(82, 63, 105, 0.15);
}

@media ( min-width: 992px ) {
    .jv-filters {
        margin: 0 8px 20px 15px;
    }
}
