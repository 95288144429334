//cards & highchart
.ant-card.criclebox {
    background-size: 100% 80%;
    background-position: bottom;
    background-repeat: no-repeat;

    .ant-card-head {
        padding: 0px 15px;
    }

    .ant-select-selection-item {
        padding: 0;
        padding-inline-end: 0px !important;
    }

    .ant-select-selector {
        border: none !important;
        padding: 2px !important;
    }

    .anticon-align-center {
        padding-right: 10px !important;
    }
}

.highcharts-background {
    fill: none !important;
}

.number span {
    font-size: 17px !important;
}

.ant-card-body {
    padding: 12px !important;
}

.ant-select-arrow {
    display: none !important;
}

.ant-select-selection-item {
    font-size: 18px;
}

.highcharts-color-0 {
    fill: #9e0b31 !important;
    stroke: #9e0b31 !important;
    .highcharts-tracker-line {
        stroke-linejoin: round;
        stroke: #9e0b31 !important;
        stroke-width: 2 !important;
    }
}

.highcharts-color-1 {
    fill: #2951a4 !important;
    stroke: #2951a4 !important;

    .highcharts-tracker-line {
        stroke-linejoin: round;
        stroke: #2951a4 !important;
        stroke-width: 2 !important;
    }
}

//login page

.jv-login__form {
    .ant-form {
        .ant-form-item {
            .ant-input-password {
                padding: 8px 16px;
            }
        }
    }
}

.jv-login .ant-input#basic_password {
    padding: 0 !important;
    height: auto;
}

.jv-login__form .ant-card {
    width: 100%;
    max-width: 400px;
}

.jv-login__password {
    text-align: center !important;
}

.ant-col {
    width: 100% !important;
}

.ant-form {
    padding: 18px !important;
}

.ant-col-sm-24 {
    margin-left: 0 !important;
}

.custom-table {
    width: auto !important; /* Set width to auto for each cell */
    height: auto !important; /* Set height to auto for each cell */
}

.custom-table .ant-table-thead > tr > th {
    white-space: nowrap;
}

.custom-table {
    width: auto;
    margin-right: 16px;
    border-collapse: collapse;
}

.custom-table th,
.custom-table td {
    width: 30%;
    max-width: 40%;
    word-wrap: break-word;
    white-space: normal !important;
    padding: 8px;
    overflow-wrap: break-word;
}
