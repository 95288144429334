@media (min-width: 992px) {
    .layout-dashboard .ant-layout-sider.sider-primary {
        margin-left: 8px;
        max-width: 270px !important;
        min-width: 270px !important;
        width: 270px !important;
    }

    .layout-dashboard .ant-layout {
        width: auto;
        flex-shrink: 1;
    }
}

@media (min-width: 768px) {
    .profile-nav-bg {
        margin-top: -87.8px;
    }

    .card-profile-head {
        margin: -53px 24px 24px;
    }

    .ant-col-md-5 {
        flex: unset !important;
        max-width: unset !important;
    }

    .layout-dashboard .ant-page-header-heading {
        margin-top: 0;
    }
}

@media (min-width: 992px) {
    .card-billing-info.ant-card .ant-card-body {
        display: flex;
    }
}

@media (max-width: 768px) {
    .ant-list .ant-list-items {
        position: absolute;
        right: 0;
    }
}
@media (max-width: 992px) {
    .transport {
        margin-top: 0 !important;
    }
}
