.ant-layout.ant-layout-has-sider {
    flex-direction: row;
}

.ant-drawer-content-wrapper {
    max-width: 280px !important;
    min-width: 280px !important;
    width: 280px !important;

    aside {
        margin-left: 14px !important;
    }
}

.layout-dashboard {
    padding: 10px 0;
    text-align: left;
    position: relative;
    overflow-x: hidden;
    max-width: 1600px;
    margin: auto;
}

@media (min-width: 992px) {
    .layout-dashboard {
        padding: 10px;
        overflow: auto;
    }

    .ant-drawer-content-wrapper {
        max-width: 290px !important;
        min-width: 290px !important;
        width: 290px !important;

        aside {
            margin-left: 0 !important;
        }
    }
}

.main_loading {
    display: grid;
    place-items: center;
    height: 100vh;

    .anticon-spin {
        font-size: 80px !important;
    }
    svg {
        font-size: 80px !important;
    }
}
