.jv-sidebar {
    padding: 0 16px;
    background-color: #fff;
    height: calc(100vh - 21px);
    overflow-x: hidden;
    border-radius: 6px;
    box-shadow: -1px -1px 8px 2px rgba(82, 63, 105, 0.15);
    width: 266px;
    position: fixed;

    &__top {
        margin: 10px 0 40px 0;
    }

    &__logo img {
        margin-left: 5hows the new px;
        width: 65%;
    }

    &__toggle-btn {
        text-align: right;

        button {
            width: 37px;
        }

        button span {
            position: relative;
            left: 3px;
        }
    }

    * {
        color: #3f4254;
        padding: 0 !important;
    }

    .ant-menu {
        background-color: #fff;
    }

    .ant-menu.ant-menu-root.ant-menu-inline {
        width: 100% !important;
    }

    .ant-menu-submenu-arrow {
        text-align: right;
    }

    .ant-menu-item:hover {
        background-color: #ecf8ff !important;

        * {
            color: #009ef7;
            transition:
                color 0.2s ease-in,
                background-color 0.6s ease-in;
        }
    }

    li.ant-menu-item.ant-menu-item-only-child {
        padding-left: 31px !important;
    }

    li.ant-menu-item.ant-menu-item-selected {
        color: #fff;
        padding: 20px 10px !important;
        background-color: #ecf8ff !important;
        transition:
            padding 0.3s ease-in,
            background-color 0.3s ease-in;
    }

    .ant-menu-root.ant-menu-inline {
        border-inline-end: none;
    }

    .anticon-expand {
        align-self: flex-start !important;
        margin-top: 2px;
    }
}
.jv-sidebar li.ant-menu-item.ant-menu-item-selected.jv-sidebar__text-extended {
    padding: 5px 12px !important;
    height: 50px;
}

.ant-menu-item .ant-menu-item-active .ant-menu-item-selected .jv-sidebar__text-extended {
    padding: 26px 10px !important;
}

.jv-sidebar__text-extended {
    margin: 10px 0 !important;
    padding: 5px !important;
    height: 50px !important;
}

.ant-menu-item .ant-menu-item-selected .jv-sidebar__text-extended  {
    padding: 28px 0px 28px 0px !important;
}

@media ( min-width: 280px ) and ( max-width: 820px ){
    .jv-sidebar {
        .anticon-expand {
            align-self: flex-start !important;
            margin-top: 5px;
        }
    }
}
