.jv-breadcrumb {
    margin: 0 15px 20px 15px;
    padding: 8px 10px 8px 0;
    color: #3f4254;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0px 3px 8px 2px rgba(82, 63, 105, 0.15);

    * {
        font-weight: normal !important;
    }

    &__left {
        margin: auto;

        & h1 {
            margin-bottom: 0;
            padding-left: 16px;
        }
    }

    &__right {
        padding: 0 10px 0 18px !important;

        .ant-space {
            gap: 18px !important;
        }

        .ant-btn {
            padding: 0 20px 0 17px;
            background-color: none;
        }
    }

    &__head {
        margin: 0 0 15px 0;
        font-size: 30px;
        font-weight: 700;
        color: rgba(71, 71, 71, 1);
        display: flex;
    }

    &__icon {
        margin-top: 2px;
        margin-right: 6px;
    }

    &__datepicker {
        box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
        border-radius: 10px;
        background-color: #fff;
        margin-top: 0 !important;
        position: absolute;
        top: 8px;
        padding: 16px;
        min-width: 350px !important;
    }

    &__dropdown {
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);

        .ant-dropdown-menu {
            margin-top: 0 !important;
            position: absolute;
            top: 8px;
            padding: 8px;
            min-width: 230px !important;
        }

        .ant-dropdown-menu-item {
            height: 38px;

            &:hover {
                color: #009ef7;
                background-color: #ecf8ff !important;
                border-radius: 10px;
                transition:
                    color 0.2s ease,
                    background-color 0.2s ease;
            }
        }
    }

    &__action.ant-btn {
        position: relative;
        font-size: 16px;
        padding-right: 24px;
        border-radius: 6px;

        &::after {
            content: '';
            position: absolute;
            right: 8px;
            top: 46%;
            transform: rotate(180deg);
            width: 0;
            height: 0;
            border-top: 4px solid transparent;
            border-right: 4px solid transparent;
            border-bottom: 6px solid rgba(71, 71, 71, 1);
            border-left: 4px solid transparent;
        }

        &.ant-popover-open,
        &.ant-dropdown-open {
            &::after {
                transform: translateY(-50%) rotate(0deg);
            }
        }
    }

    &__popover {
        &--entity {
            h2:not(:first-child) {
                margin-top: 10px;
            }
        }
    }

    &__checkbox {
        span:nth-child(2) {
            margin-top: 3px;
        }

        min-width: 170px;
        display: grid;
        grid-template-columns: 1fr;

        .ant-checkbox-wrapper {
            margin-bottom: 4px;
            padding: 5px 0;
        }

        .ant-checkbox-wrapper:hover {
            border-radius: 6px;
            background-color: #ecf8ff !important;
        }

        &--entity {
            min-width: 170px;
            display: flex;
            margin-bottom: 4px;
            padding: 5px 0;
        }

        &--entity:hover {
            border-radius: 6px;
            background-color: #ecf8ff !important;
        }

        &--all-checkbox {
            margin-bottom: 6px;
        }
    }

    &__apply {
        padding: 1px 2px 5px 6px !important;
        border-radius: 50px !important;
        width: 45px !important;
        height: 42px !important;
    }
}

@media (max-width: 575px) {
    .jv-breadcrumb {
        &__popup {
            position: absolute;
            left: -4px !important;
        }
    }
}

@media (min-width: 992px) {
    .jv-breadcrumb {
        margin: 0 8px 20px 15px;
        padding: 15px 10px 15px 0;

        &__right {
            margin-top: 0;
            text-align: right;
        }
    }
}
