.jv-footer {
    margin: 0 15px 20px 15px;
    padding: 0 2px;
    background: #fff !important;
    border-radius: 6px;
    box-shadow: 0px 3px 8px 2px rgba(82, 63, 105, 0.15);

    .ant-row {
        padding: 12px;
    }
}

@media (min-width: 992px) {
    .jv-footer {
        margin: 0 8px 2px 16px;
    }
}
