.jv-table {
    &__main-heading {
        font-weight: 600;
        white-space: pre-line;
    }

    .layout-dashboard .ant-card {
        border-radius: 5px;
    }

    .ant-card .ant-card-head {
        padding: 0px 18px 0px 15px;
        .ant-select-selector {
            .ant-select-selection-item {
                padding: 0;
            }
        }
    }

    .tablespace .ant-card-head-title {
        font-style: 18px;
    }

    table {
        table-layout: fixed !important;
    }

    .table-bg-light {
        background: #fefefe;

        .ant-table-container {
            background-color: #fefefe;
        }
        .ant-table-cell td {
            background-color: #fefefe !important;
        }
        .ant-table-thead > tr > th {
            border-start-start-radius: 0px;
            background-color: #fefefe !important;
        }
    }
    .table-bg-dark {
        background: #f5f5f5;

        .ant-table-container {
            background-color: #f5f5f5;
        }
        .ant-table-cell td {
            background-color: #f5f5f5 !important;
        }
        .ant-table-thead > tr > th {
            border-start-start-radius: 0px;
            background-color: #f5f5f5 !important;
        }
    }
    .ant-table-thead > tr > th {
        border-start-start-radius: 0px;
        background-color: #ffffff;
        .ant-table-column-sorter {
            display: none;
        }
    }

    .ant-table-thead .ant-table-cell {
        white-space: initial;
    }

    .ant-table-thead > tr > th * {
        font-weight: bold !important;
    }

    .ant-table-tbody > tr > td {
        padding: 8px 2px !important;
    }

    .ant-table-tbody > tr > td * {
        font-size: 12px !important;
        margin-right: 1px;
    }

    .ant-table-tbody > tr > td h5 {
        max-width: 74px !important;
        text-overflow: ellipsis;
        overflow: hidden !important;
    }

    .tag-primary {
        font-style: 12px;
        padding: 2px 4px;
    }

    .ant-employed {
        color: black;
        font-style: 12px;
    }

    .ant-full-box {
        box-shadow: none;
        border-style: none;
        font-size: 14px;
        &:hover {
            border-style: none !important;
            color: red !important;
        }
    }

    .uploadfile.pb-15 {
        padding-bottom: 8px;
        border: none;
        border-style: none;
    }

    .ant-progress-project {
        justify-content: unset;
    }

    .ant-card.criclebox table th {
        padding-left: 1px !important;
        border-start-start-radius: 0px !important;
        border-start-end-radius: 0px !important;
    }

    .ant-select-selector {
        border: none !important;
        font-size: 18px;
    }

    .ant-select-arrow {
        display: none !important;
    }

    .ant-progress-project {
        font-weight: 600;
        color: #8c8c8c;
    }

    .ant-select-dropdown {
        width: 200px !important;
    }

    .ant-table-tbody {
        .ant-table-cell {
            .compared {
                &.green {
                    color: #008000;
                }
                &.red {
                    color: #ff0000;
                }
            }
        }
    }

    .ant-card-body {
        padding: 0px !important;
    }

    .ant-card .ant-card-extra {
        margin-top: 6px;
        font-size: 18px;
    }

    .tablespace .ant-card-head-title {
        font-size: 20px;
    }

    .ant-card .ant-card-extra {
        margin-top: 0px;
        font-size: 16px;
    }

    &__right-info-text {
        text-align: right;
        font-size: 12px !important;
    }

    &__right-info-text-green {
        color: #32cd32;
        white-space: pre-line;
        font-weight: 600;
    }

    &__right-info-text-red {
        color: #ff0000;
        white-space: pre-line;
        font-weight: 600;
    }

    &__info-icon span svg {
        width: 22px;
        height: 16px;
        margin-top: 4px;
    }
}

.ant-select-dropdown {
    width: unset !important;
}

@media (max-width: 767px) {
    .jv-table {
        .ant-card .ant-card-extra {
            font-size: 10px;
            margin-top: 1px !important;
        }

        .tablespace .ant-card-head-title {
            font-size: 12px;
        }

        .ant-card .ant-card-head .ant-select-selector .ant-select-selection-item {
            font-size: 14px;
        }

        .ant-table-tbody > tr > td * {
            font-size: 8px !important;
        }

        .ant-card.criclebox table th {
            font-size: 8px !important;
        }

        .ant-table-tbody {
            .ant-table-cell {
                white-space: normal !important;
            }
        }
    }
}
