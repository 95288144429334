@font-face {
    font-family: 'Poppins Regular';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src:
        url('../assets/fonts/Poppins-Regular.woff2') format('woff2'),
        url('../assets/fonts/Poppins-Regular.woff') format('woff'),
        url('../assets/fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins Light';
    src:
        url('../assets/fonts/Poppins-Light.woff2') format('woff2'),
        url('../assets/fonts/Poppins-Light.woff') format('woff'),
        url('../assets/fonts/Poppins-Light.ttf') format('truetype');
    font-weight: 300;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins Bold';
    src:
        url('../assets/fonts/Poppins-Bold.woff2') format('woff2'),
        url('../assets/fonts/Poppins-Bold.woff') format('woff'),
        url('../assets/fonts/Poppins-Bold.ttf') format('truetype');
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins Italic';
    src:
        url('../assets/fonts/Poppins-Italic.woff2') format('woff2'),
        url('../assets/fonts/Poppins-Italic.woff') format('woff'),
        url('../assets/fonts/Poppins-Italic.ttf') format('truetype');
    font-display: swap;
}

$base: 15px;
$base-small: 13px;
$base-smaller: 12px;
$base-smalleest: 10px;

// Font Size Definitions
$size-xxs: 0.5rem;
$size-xs: 0.75rem;
$size-s: 1rem;
$size-m: 1.25rem;
$size-l: 1.5rem;
$size-xl: 1.75rem;
$size-xxl: 2rem;

// Font Weight Definitions
$weight-lightest: 200;
$weight-lighter: 300;
$weight-light: 400;
$weight-bold: 500;
$weight-bolder: 700;
$weight-boldest: 900;
