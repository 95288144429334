.jv-navbar {
    margin: 0 15px 20px 15px;
    padding: 15px 16px;
    color: #3f4254;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: -1px -1px 8px 2px rgba(82, 63, 105, 0.15);

    &__sidebar-toggle {
        cursor: pointer;

        svg {
            height: 20px;
            width: 22px;
        }
    }

    &__user-icon {
        cursor: pointer;
        height: 30px;
        width: 30px;
        display: flex;
        margin-right: 5px;
        align-items: center;
        justify-content: center;
    }

    &__dropdown-notification .dropdown-notifications-Header{
        text-align: right;

        & input[type=button] {
            margin: 0;
            padding: 0px 15px;
            text-decoration: none;
            border: none;
            background: none;
            cursor: pointer;
        }

        & input[type=button]:hover {
            color: #1677ff;
        }
    }
    
    &__dropdown-notification .ant-card-body{
        padding:2px 10px 2px 10px !important
    } 

    &__dropdown-notification .ant-list-items{
        margin-top: 0px !important;
        padding: 0 1px 1px 0 !important;
        width: 230px !important;
        background-color: #fff;
        border-radius: 10px;
    }

    &__dropdown-notification .ant-list-item{
        padding: 5px !important;
    }

    &__dropdown-notification .dropdown-notifications-card{
        margin-top: 11px;
        width: 250px;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
    } 

    &__dropdown-notification .dropdown-notifications-clockIcon{
       margin-top: 2px;
    }

    &__dropdown-notification .dropdown-notifications-subText{
        font-size: 14px!important;
    }

    &__dropdown-notification .dropdown-notifications-footer{
        text-align: center;
        position: relative;

        a {
            color: black;
            position: relative;
            text-decoration: none;
            top:0;
          }
          
          a:hover {
            color: #1677ff;
          }  
    }
    &__dropdown {
        position: absolute;
        top: 88px !important;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);

        .ant-dropdown-menu {
            padding: 8px;
            width: 230px !important;
        }

        .ant-dropdown-menu-item:first-child {
            padding: 0;
            height: 60px;
            flex-direction: row;

            & svg {
                margin-right: 10px;
            }

            & span {
                text-align: right;
            }

            &:hover {
                background: none;
                cursor: auto;
            }
        }

        .ant-dropdown-menu-item:not(:first-child) {
            height: 45px;
            text-align: right;
            padding-right: 0;

            &:hover {
                color: #009ef7;
                background-color: #ecf8ff;
                border-radius: 10px;
                transition:
                    color 0.2s ease,
                    background-color 0.2s ease;
            }
        }
    }

    &__right {
        right: -4px;
        top: -13px;
    }

    &__right-notification {
        position: relative;
        top: 6px;
        right: 25px;
    }

    .ant-scroll-number-only-unit.current {
        font-size: 10px !important;
        position: absolute;
        top: -1px;
        left: -3px;
    }  
}

.jv-navbar__name {
    text-transform: capitalize;
}

@media (min-width: 992px) {
    .jv-navbar {
        margin: 0 8px 20px 15px;
    }
    
    
}

@media ( min-width: 260px ) and ( max-width: 912px ){
    .jv-navbar {        
        &__dropdown-notification .dropdown-notifications-Header{
    
            & input[type=button] {
                margin: 0;
                padding: 0px 20px;
                font-size: 14px!important;
            }
        }

        &__dropdown-notification .dropdown-notifications-card{
            height: 310px;
        } 

        &__dropdown-notification .ant-list-item{
            padding: 11px!important;
        }
        
        &__dropdown-notification .dropdown-notifications-clockIcon{
            margin: 0;
            padding-top: 2px;
        }

        &__dropdown-notification .ant-list-item-meta-title {
            font-size: 14px !important;
        }

        &__dropdown-notification .dropdown-notifications-footer{
            text-align: center;
            position: relative;
            top:220px;

            a {
                top:12px;
                font-size: 14px !important;
              }
        }
    }  
}  

@media (max-width: 992px) {
    .jv-navbar {
        &__sidebar-toggle {
            visibility: visible;
        }

        &__last-updated {
            margin-left: 20px;
            vertical-align: top;
        }
    }
}