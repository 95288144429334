.jv-card {
    *,
    *::after,
    *::before {
        margin: 0;
        padding: 0;
    }
    .ant-card-body {
        padding: 16px !important;
    }

    display: flex;
    justify-content: space-evenly;

    &__card {
        max-height: 170px;
        margin-bottom: 24px;
        background-repeat: no-repeat;
        background-size: cover;
        border: none;
        box-shadow: 1px 18px 20px 0px rgb(201 195 195 / 12%);
        border-radius: 6px;
        overflow: hidden;
        padding: 15px 16px;
      

        .ant-card-body {
            padding: 10px;
        }
    }

    &__title {
        display: flex;
        justify-content: space-between;
        color: rgba(71, 71, 71, 1);
        font-weight: 600;
        font-size: 24px;
    }

    &__left-head {
        display: flex;
        justify-content: space-between;
    }

    &__amt {
        color: rgb(71, 71, 71);
        font-size: 28px;
        font-weight: 600;
    }

    &__arr-icon {
        color: rgba(204, 0, 1, 1);
        width: 38px;
        height: 38px;
    }
}

@media (max-width: 1200px) {
    .sales-trending {
        &__card {
            padding: 0 12px;
        }
        &__graph {
            margin-bottom: 24px;
        }
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .sales-trending {
        &__card {
            padding: 0;
            &:first-child {
                padding-right: 12px;
            }
            &:nth-child(2) {
                padding-left: 12px;
            }
        }
    }
}

@media (max-width: 768px) {
    .sales-trending {
        &__card {
            padding: 0;
        }
    }
}
