.jv-rangepicker {
    color: rgb(92, 92, 92);

    &__to {
        padding-left: 3px;
    }

    &__compto {
        margin: 25px 0;
    }

    &__actions {
        margin-top: 30px;

        .ant-btn {
            padding: 0 17px;
        }
    }
}
