.scroll-main {
    overflow: hidden;
    .second {
        transform: translateY(-80px);
        height: 0;
        overflow: hidden;
    }
    .third {
        transform: translateY(-459px);
        transition: all 0.5s ease-in;
        overflow: unset;
    }
}
.jv-login {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;

    .container {
        margin: auto;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__content {
        display: flex;
        justify-content: center;
        margin-top: -40px;
    }

    &__header {
        text-align: center;
        margin: auto auto 20px auto;
    }

    &__text {
        color: #fff;
        font-size: 44px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        position: relative;
        letter-spacing: 1px;

        &::after {
            position: absolute;
            content: '';
            border-left: 5px solid #a20c33;
            height: calc(100% - 32px);
            top: 24px;
            left: -26px;
        }
    }

    &__form {
        height: auto;
        background: transparent;

        .ant-card {
            background: transparent;
            width: 100%;
        }

        .ant-card-bordered {
            border: unset;
        }

        .ant-card-body {
            background-color: #ebe9e9e6;
        }
    }

    &__password {
        text-align: unset !important;
        display: flex;
        justify-content: flex-end;
        padding: 0px 4px 18px 0px;
    }

    &__title {
        font-size: 38px;
        font-weight: 700;
        margin-bottom: 16px;
    }

    &__logo {
        max-width: 160px;
        margin: 50px auto 0 auto;

        img {
            width: 100%;
        }
    }

    &__link {
        font-weight: 700;
        top: 2rem;
        left: 3rem;
        font-size: 2rem;
        background: transparent;
        border-color: transparent;
        color: blue;
        transition: all 0.3s linear;
        cursor: pointer;
        animation: bounce 2s ease-in-out infinite;
    }

    .ant-row.ant-form-item-row {
        display: block !important;
    }

    .ant-form-item .ant-form-item-label > label {
        flex-direction: row-reverse;
        color: rgba(181, 58, 70, 1);
        font-size: 16px;
        font-weight: 400;

        &::after {
            content: none;
        }
    }

    .ant-card-body {
        border-radius: 10px;
    }

    .ant-input {
        padding: 8px 16px !important;
        &#basic_password {
            padding: 4px !important;
        }
    }

    .ant-row.ant-form-item-row {
        display: block !important;
    }

    .ant-form-item .ant-form-item-label > label {
        flex-direction: row-reverse;
        color: rgba(181, 58, 70, 1);
        font-size: 16px;
        font-weight: 400;

        &::after {
            content: none;
        }
    }

    .ant-card-body {
        background-color: rgb(235, 233, 233);
        border-radius: 10px;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:focus {
        transition:
            background-color 0s 600000s,
            color 0s 600000s;
    }
    .button.ant-btn {
        padding: 0px 32px !important;
    }
    .ant-btn-primary--mod {
        padding: 0px 58px !important;
        &:disabled {
            color: #fff;
            background-color: #1677ff;
            box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
        }
    }
}
.sidebar {
    // position: fixed;
    // top: 16.5%;
    // left: 36.5%;
    // width: 350px;
    // height: 390px;
    border-radius: 8px;
    background-color: rgb(235, 233, 233);
    padding: 16px;
    display: grid;
    grid-template-rows: auto 1fr auto;
    row-gap: 1rem;
    box-shadow: #bb2525;
    transition: all 0.3s ease-in;
    transform: translateY(0);
}
.show-sidebar {
    transform: translateY(0);
}

.side {
}
// .main {
//     .anila {
//         display: none;
//     }
// }

@media (min-width: 768px) {
    .jv-login {
        .container {
            width: 50%;
        }
    }
}
