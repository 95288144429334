@import '_fonts.scss';
@import '_scrolling.scss';

* {
    font-family: 'Poppins Regular', sans-serif !important;
    font-size: $base-smaller !important;
    font-weight: 400;
    line-height: 1.625;
    letter-spacing: 0.00938em;
}

h1,
h2,
h3,
h4,
h5,
h6,
strong,
b {
    font-family: 'Poppins Bold' !important;
}

button.ant-btn {
    padding: 6px 20px;
    font-size: 15px;
    height: auto;
    font-family: 'Poppins Regular', sans-serif !important;
}

@media (min-width: 992px) {
    * {
        font-size: $base !important;
    }
}

.ant-popover-content {
    width: 400px;

    .ant-popover-inner-content {
        font-size: 12px !important;
    }
}
